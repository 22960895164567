<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-bottom-30 mb-10">
      <v-card-title class="body-1">
        การเงิน <v-icon>mdi-chevron-right</v-icon><span>ใบแจ้งหนี้ สาขา </span>
      </v-card-title>
    </v-card>

    <v-card class="v-card-bottom-30  mb-10">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <span>วันที่ : </span>
          </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from"
                      @change="value => computed_search_date_from = value"
                      autocomplete="off"
                      label="เริ่ม"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <span>เลขที่เอกสาร : </span>
          </v-col>
          <v-col cols="12" md="4" lg="4" class="pb-3">
            <v-text-field
              v-model="sh_invoice_no"
              autocomplete="on"
              placeholder="INV-xxxxxx-xxx"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3"></v-col>
          <v-col cols="12" md="6">
            <v-btn color="primary" @click="get_data_invoice_brance(), descriptionIndex = null">ค้นหา</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-10">
      <v-card-title>
        <v-icon size="20" color="success">fa-receipt</v-icon><span class="my-3 ml-3">รายการใบแจ้งหนี้ </span>
        <v-spacer/>
        <!-- <v-btn v-if="data_invoice_list.length > 0" color="success" outlined @click="Export_to_excel()">Export to Excel</v-btn> -->
        <v-btn v-show="data_invoice_list.length != 0" size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
          <vue-excel-xlsx
              :data="data_invoice_list"
              :columns="excel_invoice_list_header"
              :file-name="'รายงานใบแจ้งหนี้'"
              :sheet-name="'รายงานใบแจ้งหนี้'"
              >
              Export to Excel
          </vue-excel-xlsx>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-5">
            <!-- แสดงจอ PC -->
            <div class="hidden-xs-only">
              <v-data-table
                  :headers="headers"
                  :key="0"
                  :items="data_invoice_list"
                  :footer-props="footer_props"
                  item-key="id"
                  class="elevation-0 packhai-border-table"
                  :mobile-breakpoint="0"
                >
                <template v-slot:item.invoiceNo="{ item }">
                  <a @click="seeMore_Invoice_List(item), shopDisibled = true, branchDisibled = true">{{ item.invoiceNo }}</a>
                </template>


                <template v-slot:item.createdDatetime="{ item }">
                  {{ set_format_date_time(item.createdDatetime) }}
                </template>

                <template v-slot:item.calculatedPrice="{ item }">
                  {{ format_price(item.calculatedPrice) }}
                </template>
                <template v-slot:item.vatAmount="{ item }">
                  {{ format_price(item.vatAmount) }}
                </template>
                <template v-slot:item.totalPrice="{ item }">
                  {{ format_price(item.totalPrice) }}
                </template>
                <template v-slot:item.totalItemPrice="{ item }">
                  {{ format_price(item.totalItemPrice) }}
                </template>

                <template v-slot:item.invoiceDate="{ item }">
                  <span v-if="item.invoiceDate != null">{{ set_format_date_time(item.invoiceDate).substring(0, 10) }}</span>
                </template>

                <template v-slot:item.vatType="{ item }">
                  {{ format_vat_type(item.vatType) }}
                </template>

                <template v-slot:item.CancelInvoice="{ item }">
                  <v-btn icon><v-icon size="18" color="red" @click="check_delete(item)">fa-trash-alt</v-icon></v-btn>
                  <!-- <v-btn icon @click="CancelInvoiceConfirm(item.id)"><v-icon color="red" size="18" >fa-trash-alt</v-icon></v-btn> -->
                </template>

                <template v-slot:item.Print="{ item }">
                    <!-- <v-btn icon><v-icon>mdi-printer</v-icon></v-btn> -->
                    <v-btn icon @click="dialog_print_open(item)"><v-icon color="success">mdi-printer</v-icon></v-btn>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog v-model="dialog_select_print" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ปริ้น
          <v-spacer/>
          <v-btn icon @click="dialog_select_print=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <div class="py-3">
            <v-radio-group
              v-model="select_print_type"
              row
            >
              <v-radio
                label="ใบแจ้งหนี้ "
                :value="1"
              ></v-radio>
              <v-radio
                label="ใบเสร็จรับเงิน / ใบกำกับภาษี"
                :value="2"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="pt-3 pb-5">
            <v-btn outlined color="info" @click="PrintPreview(item_for_print)">
              ตกลง
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import {  reportService_dotnet } from '@/website/global'
  import { formatMoney, isNumberWNoDot, isNumberWithDot, isNumberWitMark, FormatInvoiceBranch, set_format_date_time, format_vat_type, format_price} from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    computed: {
      itemsPerPageBranch(){
        return this.dataBranch.length
      },
      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    watch: {
      filterSelect: async function() {
        await this.get_data_invoice_brance()
      }
    },
    data: () => ({
      page_loading: true,
      loading: false,

      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      sh_invoice_no: null,

      balance: 0,

      headers: [
        { text: 'Invoice No', align: 'center', sortable: false, value: 'invoiceNo', width : "150px"},
        { text: 'วันที่สร้าง', align: 'center', sortable: false, value: 'createdDatetime', width : "150px"},
        { text: 'วันที่ใบแจ้งหนี้', align: 'center', sortable: false, value: 'invoiceDate', width : "150px"},
        { text: 'สร้างโดย', align: 'center', sortable: false, value: 'createdByName', width : "200px"},
        { text: 'สาขา', align: 'left', sortable: false, value: 'branchName', width : "200px"},
        { text: 'ภาษี', align: 'center', sortable: false, value: 'vatType', width : "150px"},
        { text: 'ยอดรวม', align: 'right', sortable: false, value: 'totalItemPrice', width : "100px"},
        { text: 'ยอดที่คำนวนภาษี', align: 'right', sortable: false, value: 'calculatedPrice', width : "150px"},
        { text: 'ภาษีมูลค่าเพิ่ม', align: 'right', sortable: false, value: 'vatAmount', width : "150px"},
        { text: 'ยอดรวมทั้งหมด', align: 'right', sortable: false, value: 'totalPrice', width : "150px"},
        // { text: 'ยกเลิก', align: 'center', sortable: false, value: 'CancelInvoice', width : "100px"},
        { text: 'ปริ้น', align: 'center', sortable: false, value: 'Print', width : "100px"},
      ],
      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },
      data_invoice_list: [],


      // print
      dialog_select_print: false,
      select_print_type: 1,
      item_for_print: null,

      //export to excel
      excel_invoice_list_header : [
          { label: "Invoice No", field: "invoiceNo", width: 15},
          { label: "วันที่สร้าง", field: "createdDatetime", width: 20 ,dataFormat: set_format_date_time},
          { label: "วันที่ใบแจ้งหนี้", field: "invoiceDate", width: 20 ,dataFormat: set_format_date_time},
          { label: "สร้างโดย", field: "createdByName",  width: 30 },  //dataFormat: format_number,
          { label: "สาขา", field: "branchName", width: 40 },
          { label: "ภาษี", field: "vatType", width: 15, dataFormat: format_vat_type },
          { label: "ยอดรวม", field: "totalItemPrice", width: 15 },
          { label: "ยอดที่คำนวนภาษี", field: "calculatedPrice", width: 15},
          { label: "ภาษีมูลค่าเพิ่ม", field: "vatAmount", width: 15},
          { label: "ยอดรวมทั้งหมด", field: "totalPrice", width: 15},
      ],



      // สาขา
    }),
    async created() {
      await this.get_data_invoice_brance()
      this.page_loading = false
    },
    methods: {
      formatMoney,
      isNumberWNoDot,
      isNumberWithDot,
      isNumberWitMark,
      FormatInvoiceBranch,
      set_format_date_time,
      format_vat_type,
      format_price,

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },


      async get_data_invoice_brance() {

        if(this.sh_invoice_no == ''){
          this.sh_invoice_no = null
        }

        this.loading = true
        let res1 = await axios.post(reportService_dotnet+'HQInvoice/invoice-list', {
          "branchID": localStorage.getItem('Branch_BranchID'),
          "invoiceNo": this.sh_invoice_no,
          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to,
          "skip": 0,
          "take": 1000000
        },
        { headers: header_token})
        // console.log(res1.data);
        this.data_invoice_list = res1.data.invoiceList
        const dateFrom_tmp = res1.data.dateFrom.split("-")
        const dateTo_tmp = res1.data.dateTo.split("-")
        this.search_date_from = dateFrom_tmp[2] + '-' + dateFrom_tmp[1] + '-' + dateFrom_tmp[0]
        this.search_date_to = dateTo_tmp[2] + '-' + dateTo_tmp[1] + '-' + dateTo_tmp[0]

        this.loading = false
      },


      seeMore_Invoice_List (row) {
        if (row.hqInvoiceGroupID != null ) {
          var inv =  row.id == null ? null : row.id.toString()
          var invGroup = row.hqInvoiceGroupID == null ? null : row.hqInvoiceGroupID.toString()
          window.open('/branch-view-detail-invoice?inv=' + inv + '&invGroup=' + invGroup, '_blank');
        } else if (row.hqInvoiceGroupID == null && row.isSelfGenerate == true) {
          window.open('branch-invoice-detail?id=' +row.id.toString())
        }else {
          window.open('/branch-view-detail-invoice-express?inv=' + row.invoiceNo +'&branch_id=' + row.branchID.toString())
        }

      },


      dialog_print_open (item) {
        this.item_for_print = item
        this.dialog_select_print = true
      },

      async PrintPreview(item){
        var hqInvoiceGroupID_tmp = item.hqInvoiceGroupID
        if (hqInvoiceGroupID_tmp != null){
          hqInvoiceGroupID_tmp = hqInvoiceGroupID_tmp.toString()
        }
        window.open('/print-branch-invoice?inv=' + item.id.toString() + "&group=" + hqInvoiceGroupID_tmp + '&type='+this.select_print_type.toString());
        this.select_print_type = 1
      },


    }
  }
</script>
